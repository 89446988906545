<template>
  <div class="setting-item s-exclude-min-max-relative-date">
    <div>{{ localSetting.name }}</div>
    <div class="setting-item__child-settings">
      <esmp-input
        class="setting-item__child-setting"
        v-if="isYearShown"
        v-model="year"
        label="лет"
      />
      <esmp-input
        class="setting-item__child-setting"
        v-model="month"
        label="месяцев"
      />
      <esmp-input
        class="setting-item__child-setting"
        v-model="day"
        label="дней"
      />
      <esmp-input
        class="setting-item__child-setting"
        v-model="hour"
        label="часов"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SExcludeMinMaxRelativeDate',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isYearShown() {
      return this.setting.techName !== 'excludeMinRelativeDate'
        && this.setting.techName !== 'excludeMaxRelativeDate';
    },
    year: {
      get() {
        return this.localValue[0] || '0';
      },
      set(v) {
        this.setValue({ year: v });
      },
    },
    month: {
      get() {
        return this.localValue[1] || '0';
      },
      set(v) {
        this.setValue({ month: v });
      },
    },
    day: {
      get() {
        return this.localValue[2] || '0';
      },
      set(v) {
        this.setValue({ day: v });
      },
    },
    hour: {
      get() {
        return this.localValue[3] || '0';
      },
      set(v) {
        this.setValue({ hour: v });
      },
    },
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    localValue() {
      return this.localSetting.value?.split('-') || [];
    },
  },
  methods: {
    setValue({
      year, month, day, hour,
    }) {
      const value = `${ year || this.year }-${ month || this.month }-${ day || this.day }-${ hour || this.hour }`;
      this.localSetting.value = value === '0-0-0-0' ? '' : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-item {
  &__child-settings {
    display: flex;
  }
  &__child-setting:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
